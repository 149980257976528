<template>
  <div id="print" class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="elevator/archive/common/customer/page">
      <el-button
        v-if="$auth('常规信息新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="handleSeniorClick">
        {{$l("common.search", "高级")}}
      </el-button>

      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="楼宇名称">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="出厂编号">
            <el-input v-model.trim="filter.factoryCode" clearable></el-input>
          </vm-search>
          <vm-search label="电梯在使用单位内部编号">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search v-if="senior" label="电梯启用日期">
            <el-date-picker
              v-model="filter.factoryDate"
              type="date"
              value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="选择日期">
            </el-date-picker>
          </vm-search>
          <vm-search v-if="senior" label="使用类型">
            <vm-dict-select v-model="filter.useType" type="elevatorUseType"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="控制系统">
            <vm-dict-select v-model="filter.controlSystem" type="elevatorControlSystem"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="是否有机房">
            <el-select v-model="filter.machineRoom">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search v-if="senior" label="电梯品牌">
            <el-input v-model.trim="filter.brandName" clearable></el-input>
          </vm-search>
          <vm-search v-if="senior" label="电梯型号">
            <el-input v-model.trim="filter.modelName" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <div slot="tree" :style="{width:width+'px'}" class="treeBox">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </div>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘楼宇" align="center" width="170">
        <template slot-scope="scope">
          {{scope.row.realEstateName+'-'+scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="电梯在使用单位内部编号" align="center" width="170"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="170"></el-table-column>
      <el-table-column prop="regDate" label="注册日期" align="center" width="170"></el-table-column>
      <el-table-column prop="maintenanceTypeDesc" label="维保类型" align="center"></el-table-column>
      <el-table-column prop="useTypeDesc" label="用途分类" align="center"></el-table-column>

      <el-table-column prop="brandName" label="品牌/型号" align="center">
        <template slot-scope="scope">
          {{scope.row.brandName+'/'+scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column prop="factoryCode" label="出厂编号" align="center"></el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
        <template slot-scope="scope">
          <!--          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>-->
          <el-button v-if="$auth('客户常规信息详情')" type="primary" @click="$refs.elevatorDetail.open(scope.row.id)">{{$l("common.edit", "详情")}}</el-button>
          <!--          <el-button-->
          <!--            :title="!scope.row.iotDeviceCode?'未绑定终端设备':scope.row.online?'在线':'离线'"-->
          <!--            type="success"-->
          <!--            @click="gotoMonitor(scope.row.id)">-->
          <!--            {{$l("monitor.monitor", "监控")}}-->
          <!--          </el-button>-->
          <!--          <el-button type="primary" @click="goToDetail(scope.row.id)">{{$l("common.detail", "详情")}}</el-button>-->
        </template>
      </el-table-column>
    </vm-table>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
  </div>
</template>
<script>
  import ElevatorDetail from "./ElevatorDetail";
  import {getWidth} from "@/util";
  import print from  "print-js";
  import {downloadFiles} from "@/util";
  export default {
    components: {ElevatorDetail},
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
          factoryDate:"",
          realEstateName:"",

        },
        senior: false,
        data:[],
        width:200,
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      printSomething() {
        print({
          printable:"print",
          type:"html",
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.name}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/elevator-archive/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      gotoMonitor(elevatorId) {
        let routeUrl = this.$router.resolve({
          path: "/monitor/LiftMonitor",
          query: {elevatorId},
        });
        window.open(routeUrl.href, "_blank");
      },
      goToDetail(elevatorId) {
        this.$router.push({path:"/elevator/Elevatordetail",query:{elevatorId}});
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-real-estate/customer");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {

        if (e.other!=="district") {
          this.filter.realEstateName=e.label;
          this.getList(-1);
        }

      },
      async downFile() {
        this.loading=true;
        downloadFiles("excelTemplate/elevatorArchive.xlsx","电梯档案.xlsx",()=> {
          this.loading=false;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 1080px;
  overflow-y: auto;
}
</style>

<template>
  <el-tabs value="first" @tab-click="handleClick">
    <el-tab-pane v-if="$auth('客户电梯档案常规信息')" label="常规信息" name="first"><elevator-list></elevator-list></el-tab-pane>
    <el-tab-pane v-if="$auth('客户电梯档案远程监测')" label="远程监测" name="second"><elevator-remote-list></elevator-remote-list></el-tab-pane>
    <el-tab-pane v-if="$auth('客户电梯档案累计数据')" label="累计数据" name="third"><elevator-data-statistic></elevator-data-statistic></el-tab-pane>
  </el-tabs>
</template>

<script>
  import ElevatorList from "@/views/customerFunction/ElevatorList";
  import ElevatorRemoteList from "@/views/customerFunction/ElevatorRemoteList";
  import ElevatorDataStatistic from "./ElevatorDataStatistic";
  export default {
    components:{ElevatorRemoteList,ElevatorList,ElevatorDataStatistic},
  };
</script>

<style scoped>

</style>
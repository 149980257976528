<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="查看历史统计数据"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <vm-table
      ref="vmTable"
      :immediately="true"
      :filter.sync="filter"
      url="elevator-archive/elevator-statistics/page"
      :border="true"
    >
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="开始日期">
            <el-date-picker
              v-model="filter.timestampFrom"
              type="date"
              value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="选择日期">
            </el-date-picker>
          </vm-search>
          <vm-search label="结束日期">
            <el-date-picker
              v-model="filter.timestampTo"
              type="date"
              value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="选择日期">
            </el-date-picker>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="timestamp" label="日期" align="center"></el-table-column>
      <el-table-column prop="totalRunningTime" label="累计运行时间" align="center"></el-table-column>

      <el-table-column prop="totalRunningCount" label="累计运行次数" align="center"></el-table-column>
      <el-table-column prop="totalRunningDistance" label="累计运行距离" align="center"></el-table-column>
      <el-table-column prop="totalDoorOpeningCount" label="累计开门次数" align="center"></el-table-column>
      <el-table-column prop="totalRopeBendingCount" label="钢丝绳累计折弯次数" align="center" width="155"></el-table-column>
      <el-table-column prop="totalSlightMovementCount" label="累计微动次数" align="center" width="150"></el-table-column>
    </vm-table>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>



  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        tabActive: "first",
        floorStart: 0,
        floorEnd: 0,
        id:0,
        model: [],
        filter:{
          orderColumnName:"timestamp",
        },
      };
    },

    methods: {
      open(id) {
        this.model.id = id;
        this.id =id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.id) {
          const parmars  = {...this.filter, elevatorId:this.id};
          // this.contentLoading = true;
          this.$http.get("/elevator-archive/elevator-statistics/page",parmars).then(data => {
            this.contentLoading = false;
            this.model = data.records;
          });
        } else {
          // this.initMap();
        }
      },


    },
  };
</script>

<style lang="scss" scoped>
</style>
